.label {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label__name {
  order: -1;
  margin: 0;
  color: #757575;
  font-size: 14px;
  line-height: 1.2;

  span {
    display: none;
  }
}

.label.required {
  .label__name span {
    display: flex;
  }
}

.label__input {
  padding: 14px 12px 14px 9px;
  width: 100%;
  color: #2E2E2E;
  border-radius: 4px;
  background: #F2F1F2;
  border: 1px solid #F2F1F2;
  transition: border-color 0.3s;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: #6e387b;
    outline: 0;
  }
}

.label__select {
  appearance: none;
  padding: 14px 12px 14px 9px;
  width: 100%;
  height: 46px;
  color: #2E2E2E;
  border-radius: 4px;
  background: #F2F1F2;
  border: 1px solid #F2F1F2;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: #6e387b;
    outline: 0;
  }
}