.fb-form {
  position: relative;
  margin-block: 50px;
  border-radius: 4px;
  background: #6E387B;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &:before {
    width: 236px;
    background-color: rgba(68, 164, 173, 0.20);
    clip-path: polygon(0 0, 100% 0, calc(100% - 150px) 100%, 0% 100%);
  }

  &::after {
    width: 180px;
    background-color: #44A4AD;
    clip-path: polygon(0 0, calc(100% - 150px) 0, 100% 100%, 0% 100%);
  }
}

.fb-form__img {
  display: none;
}

.fb-form__content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 24px 19px 27px 19px;
  z-index: 1;

  .item-form__inputs {
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: unset;
  }

  .item-form__input {
    width: 100%;
    
  }

  .item-form__input input[type="text"], 
  .item-form__input input[type="tel"] {
    height:44px;
  }
}

.fb-form__name {
  margin: 0;
  text-align: center;
  color: #FFF;
  font-size: 30px;
  line-height: 1.34;
  text-transform: uppercase;
}

.item-form__inputs--column {
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;

  .item-form__input {
    width: 100%;
  }
}

@media (min-width: 768px) {

  .fb-form {
    &::before {
      width: 395px;
    }

    &:after {
      width: 395px;
    }
  }
  .fb-form__content {

    .item-form__inputs {
      display: grid;
      grid-template-columns: repeat(3,minmax(0, 1fr));
      column-gap: 15px;
    }

    .item-form__input--full {
      grid-column: 1 / -1;
    }
  }
}

@media (min-width: 1024px) {
  .fb-form__img {
    position: relative;
    display: flex;
    z-index: 1;

    img {
      width: 100%;
      max-width: 217px;
      height: 100%;
      object-fit: cover;
      object-position: right center;
    }
  }

  .fb-form {
    display: grid;
    grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
    padding: 0;
    margin-block: 62px;
  }

  .fb-form__content {
    padding: 44px 38px 44px 0;
  }

  .fb-form__name {
    text-align: left;
  }
}

.blog_block {
  overflow: hidden;
}