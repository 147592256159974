@media (min-width: 1024px) {
  .prices-block {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 315px;
    gap: 20px;
    padding-top: 70px;
  }

  .prices-block__form {
    position: relative;
    padding-top: 73px;

    .right-form {
      position: sticky;
      top: 0;
    }
  }
}