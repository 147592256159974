.ancors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  padding-top: 20px;
}

.ancors__item {
  display: flex;
  align-items: center;
  justify-content: center;
}