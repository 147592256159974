.header__bottom.fixed {
  position: fixed;
  top: 0;
  width: 100svw;
  background-color: white;
}

.bottom-menu.fixed {
  top: 78px;
  position: fixed;
  width: 100svw;
  z-index: 100;
  overflow-y: auto;
}