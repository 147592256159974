.custom-checkbox--dark {
  input[type="checkbox"] + label {
    color: #2E2E2E;
  }
}
@media (min-width: 1024px) {
  .right-form {
    padding: 37px 40px 37px 40px;
    border-radius: 4px;
    background-image: url('/common/htdocs/build/img/right-form-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .right-form__name {
    margin: 0;
    color: #6E387B;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
  }

  .right-form__text {
    margin-top: 0;
    margin-bottom: 20px;
    color: #2E2E2E;
    line-height: 1.4;
  }
}