html {
  scroll-behavior: smooth;
}
@import "../html/sourses/sourses.scss";
@import "../html/fb-form/fb-form.scss";
@import "../html/mobile-sales/mobile-sales.scss";
@import "../html/ancors/ancors.scss";
@import "../html/right-form/right-form.scss";
@import "./ads.scss";
@import "./label.scss";
@import "./prices-block.scss";
@import "../html/appointment/appointment.scss";