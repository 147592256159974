html {
  scroll-behavior: smooth;
}

.article-sources {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  padding: 20px 30px;
  border: 1px solid #e2e2e2;
  margin-top: 30px;
}

.article-sources__title {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.article-sources__text ol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding-left: 20px;
}
.article-sources__text li {
  padding-left: 10px;
}
.article-sources__text li::marker {
  color: #6e387b;
}

@media (min-width: 768px) {
  .article-sources {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 4fr);
  }
  .article-sources__title {
    border-right: 1px solid #e2e2e2;
  }
}
.fb-form {
  position: relative;
  margin-block: 50px;
  border-radius: 4px;
  background: #6E387B;
}
.fb-form:before, .fb-form:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.fb-form:before {
  width: 236px;
  background-color: rgba(68, 164, 173, 0.2);
  clip-path: polygon(0 0, 100% 0, calc(100% - 150px) 100%, 0% 100%);
}
.fb-form::after {
  width: 180px;
  background-color: #44A4AD;
  clip-path: polygon(0 0, calc(100% - 150px) 0, 100% 100%, 0% 100%);
}

.fb-form__img {
  display: none;
}

.fb-form__content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 24px 19px 27px 19px;
  z-index: 1;
}
.fb-form__content .item-form__inputs {
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: unset;
}
.fb-form__content .item-form__input {
  width: 100%;
}
.fb-form__content .item-form__input input[type=text],
.fb-form__content .item-form__input input[type=tel] {
  height: 44px;
}

.fb-form__name {
  margin: 0;
  text-align: center;
  color: #FFF;
  font-size: 30px;
  line-height: 1.34;
  text-transform: uppercase;
}

.item-form__inputs--column {
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}
.item-form__inputs--column .item-form__input {
  width: 100%;
}

@media (min-width: 768px) {
  .fb-form::before {
    width: 395px;
  }
  .fb-form:after {
    width: 395px;
  }
  .fb-form__content .item-form__inputs {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 15px;
  }
  .fb-form__content .item-form__input--full {
    grid-column: 1/-1;
  }
}
@media (min-width: 1024px) {
  .fb-form__img {
    position: relative;
    display: flex;
    z-index: 1;
  }
  .fb-form__img img {
    width: 100%;
    max-width: 217px;
    height: 100%;
    object-fit: cover;
    object-position: right center;
  }
  .fb-form {
    display: grid;
    grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
    padding: 0;
    margin-block: 62px;
  }
  .fb-form__content {
    padding: 44px 38px 44px 0;
  }
  .fb-form__name {
    text-align: left;
  }
}
.blog_block {
  overflow: hidden;
}

.mobile-sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  color: white;
  font-weight: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #6e387b;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .mobile-sales {
    display: none;
  }
}
.ancors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  padding-top: 20px;
}

.ancors__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox--dark input[type=checkbox] + label {
  color: #2E2E2E;
}

@media (min-width: 1024px) {
  .right-form {
    padding: 37px 40px 37px 40px;
    border-radius: 4px;
    background-image: url("/common/htdocs/build/img/right-form-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .right-form__name {
    margin: 0;
    color: #6E387B;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .right-form__text {
    margin-top: 0;
    margin-bottom: 20px;
    color: #2E2E2E;
    line-height: 1.4;
  }
}
.header__bottom.fixed {
  position: fixed;
  top: 0;
  width: 100svw;
  background-color: white;
}

.bottom-menu.fixed {
  top: 78px;
  position: fixed;
  width: 100svw;
  z-index: 100;
  overflow-y: auto;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label__name {
  order: -1;
  margin: 0;
  color: #757575;
  font-size: 14px;
  line-height: 1.2;
}
.label__name span {
  display: none;
}

.label.required .label__name span {
  display: flex;
}

.label__input {
  padding: 14px 12px 14px 9px;
  width: 100%;
  color: #2E2E2E;
  border-radius: 4px;
  background: #F2F1F2;
  border: 1px solid #F2F1F2;
  transition: border-color 0.3s;
}
.label__input:hover, .label__input:focus, .label__input:focus-within {
  border-color: #6e387b;
  outline: 0;
}

.label__select {
  appearance: none;
  padding: 14px 12px 14px 9px;
  width: 100%;
  height: 46px;
  color: #2E2E2E;
  border-radius: 4px;
  background: #F2F1F2;
  border: 1px solid #F2F1F2;
}
.label__select:hover, .label__select:focus, .label__select:focus-within {
  border-color: #6e387b;
  outline: 0;
}

@media (min-width: 1024px) {
  .prices-block {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 315px;
    gap: 20px;
    padding-top: 70px;
  }
  .prices-block__form {
    position: relative;
    padding-top: 73px;
  }
  .prices-block__form .right-form {
    position: sticky;
    top: 0;
  }
}
#appintmetn-service {
  top: 50%;
  transform: translateY(-50%);
}

.form-new__block {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.form-new__row--2cols {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 10px 5px;
}

.form-new__check {
  padding-top: 30px;
  padding-bottom: 20px;
}

.form-new__row .btn {
  width: 100%;
}

.form-new__opener {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 33px;
  color: #757575;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
}
.form-new__opener span {
  display: flex;
  align-items: center;
  transform: rotate(180deg);
}
.form-new__opener:hover {
  color: #6E387B;
}

@media (min-width: 768px) {
  .form-new__row--2cols {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.popup-block {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 40px;
}

.popup-block__name {
  margin: 0;
  color: #6E387B;
  font-family: "Gotham Pro";
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.popup-block__head {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 18px;
}

.popup-block__text {
  margin: 0;
  color: #2E2E2E;
  font-size: 12px;
  line-height: 1.4;
}

.popup-block__tel {
  margin: 0;
  color: #2D2D2D;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-decoration: none;
  transition: color 0.3s;
}
.popup-block__tel:hover {
  color: #6E387B;
}

.popup-block__left {
  order: 1;
  padding-top: 40px;
  border-top: 1px solid #DDDCDB;
}

@media (min-width: 1024px) {
  .popup-block {
    grid-template-columns: 210px minmax(0, 1fr);
  }
  .popup-block__left {
    order: 0;
    padding-top: 20px;
    padding-right: 40px;
    border-top: 0;
    text-align: right;
    border-right: 1px solid #DDDCDB;
  }
  .popup-block__left .popup-block__text {
    margin-bottom: 37px;
  }
}