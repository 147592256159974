.article-sources {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  padding: 20px 30px;
  border: 1px solid #e2e2e2;
  margin-top: 30px;
}

.article-sources__title {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.article-sources__text {
  ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding-left: 20px;
  }

  li {
    padding-left: 10px;

    &::marker {
      color: #6e387b;;
    }
  }
}

@media (min-width: 768px) {
  .article-sources {
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0, 4fr);
  }

  .article-sources__title {
    border-right: 1px solid #e2e2e2;
  }
}