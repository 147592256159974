#appintmetn-service {
  top: 50%;
  transform: translateY(-50%);
}

.form-new__block {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.form-new__row--2cols {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 10px 5px;
}

.form-new__check {
  padding-top: 30px;
  padding-bottom: 20px;
}

.form-new__row .btn {
  width: 100%;
}

.form-new__opener {
  display:flex;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 33px;
  color: #757575;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;    
    transform: rotate(180deg);
  }

  &:hover {
    color: #6E387B;
  }

}

@media (min-width: 768px) {
  .form-new__row--2cols {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.popup-block {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 40px;
}

.popup-block__name {
  margin: 0;
  color: #6E387B;
  font-family: "Gotham Pro";
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.popup-block__head {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 18px;
}

.popup-block__text {
  margin: 0;
  color: #2E2E2E;
  font-size: 12px;
  line-height: 1.4;
}

.popup-block__tel {
  margin: 0;
  color: #2D2D2D;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #6E387B;
  }
}

.popup-block__left {
  order: 1;
  padding-top: 40px;
  border-top: 1px solid #DDDCDB;
}

@media (min-width: 1024px) {
  .popup-block {
    grid-template-columns: 210px minmax(0, 1fr);
  }
  .popup-block__left {
    order: 0;
    padding-top: 20px;
    padding-right: 40px;
    border-top: 0;

    text-align: right;
    border-right: 1px solid #DDDCDB;

    .popup-block__text {
      
      margin-bottom: 37px;
    }
  }
}