.mobile-sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  color: white;
  font-weight: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #6e387b;
  text-decoration: none;;
}

@media (min-width: 1024px) {
  .mobile-sales {
    display: none;
  }
}